












































import { Component, Mixins } from 'vue-property-decorator'
import { Game, Shop } from '@/api'
import { Toast } from '@/mixins'
import { GameModule } from '@/store/modules'

@Component
export default class CouponModal extends Mixins(Toast) {
  @GameModule.State('characters') charactersFromAPI

  modal = false
  coupon: Coupon | null = null
  error = false
  characterSelected: string | null = null
  names = []

   get characters() {
    const characters = this.charactersFromAPI.map(c => {
      return {
        value: c.id,
        text: c.name
      }
    })

    characters.unshift({
      value: null,
      text: 'Select character'
    })

    return characters
  }

  async mounted() {
    this.$root.$on('openCouponModal', async coupon => {
      const items = coupon.pendingRewards.map(e => e.itemVnum)

      this.names = await Game.getItemsNames(items)
      this.modal = true
      this.coupon = coupon
    })
  }

  getItemNameByID(id) {
    // @ts-ignore
    return this.names.find(e => e.vnum === id)?.name
  }

  async claimCoupon() {
    const res = await Shop.redeemCouponOnStash({
      stashId: this.coupon!.id as string,
      characterId: this.characterSelected as string
    })

    if (res.status != 400) {
      this.modal = false
      this.characterSelected = null

      this.showSuccessToast({
        title: 'Successfully claimed!',
        message: `You successfully claimed the coupon ${this.coupon!.id}. Items have been sent.`
      })
      this.$root.$emit('refreshCouponStash')
    } else {
      this.error = true
    }
  }
}
